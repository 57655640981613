import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import TraceabilityAccordion from '../components/Traceability/TraceabilityAccordion'
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import ContentBlocksCallToAction from '../components/ContentBlocks/ContentBlocksCallToAction'

const footerLinks = ['the-kawatea', 'pot-fishing', 'past']

class TraceabilityPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeId: '',
      activeProduct: '',
      activeProductDate: '',
    }
  }

  toggleProductAccordion = (value, id) => {
    this.setState({
      activeProductDate: '',
    })
    if (value === this.state.activeProduct) {
      this.setState({
        activeProduct: '',
        activeId: '',
      })
    } else {
      this.setState({
        activeProduct: value,
        activeId: id,
      })
    }
  }

  toggleProductDate = value => {
    this.setState({
      activeProductDate: value,
    })
  }

  render() {
    const { location, data } = this.props

    const page = data.prismicTraceability.data
    const products = data.allPrismicProducts.nodes

    return (
      <Layout>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <Hero
          title="Traceability"
          preTitle="Okains Bay"
          image={data.heroImage.childImageSharp.fluid}
          gradient
        />
        <div className="pb-20 gutters">
          <div className="py-24 lg:mx-auto lg:w-18/24 flex flex-col md:flex-row items-start">
            <div className="lg:w-1/18"></div>
            {page.lead_image !== null && (
              <div className="mb-8 lg:mb-0 md:flex-shrink-0">
                <Fade delay={225} duration={2250}>
                  <div className="bg-black pt-7 p-4">
                    <h4 className="text-xs uppercase tracking-widest mb-4">
                      Okains Bay Sustainability
                    </h4>
                    <div>
                      <Img
                        fluid={page.lead_image.localFile.childImageSharp.fluid}
                        alt={page.lead_image.alt}
                      />
                    </div>
                  </div>
                </Fade>
              </div>
            )}
            <div className="w-2/18"></div>
            <div className="lg:w-10/18">
              {page.lead_text.html !== null && (
                <Fade delay={450} duration={2250}>
                  <div
                    className="rte text-lg md:text-xl lg:text-2xl font-light leadwing-snug"
                    dangerouslySetInnerHTML={{
                      __html: page.lead_text.html,
                    }}
                  ></div>
                </Fade>
              )}
            </div>
          </div>
          <Fade delay={225} duration={2250}>
            <div className="py-12 md:pb-18 md:pb-20 lg:pt-20">
              <h1 className="uppercase text-3xl md:text-6xl font-medium tracking-wide">
                Traceability
              </h1>
            </div>
            <div>
              <h4 className="uppercase tracking-wider text-xs mb-8">
                Select your product
              </h4>
              <ul
                className={`border-t transition-border-color transition-200 transition-custom ${
                  this.state.activeId !== ''
                    ? this.state.activeId !== 0
                      ? 'border-white-opaque-20'
                      : 'border-white-opaque-50'
                    : 'border-white-opaque-50'
                }`}
              >
                {products.map((item, index) => {
                  return (
                    <TraceabilityAccordion
                      key={index}
                      id={index}
                      data={item}
                      toggleProductAccordion={this.toggleProductAccordion}
                      toggleProductDate={this.toggleProductDate}
                      activeProductDate={this.state.activeProductDate}
                      activeProduct={this.state.activeProduct}
                      activeId={this.state.activeId}
                    />
                  )
                })}
              </ul>
            </div>
          </Fade>
        </div>
        <ContentBlocksCallToAction
          title={page.cta_title}
          text={page.cta_text}
          image={page.cta_image}
          alt={page.cta_image.alt}
          link={page.cta_link.uid}
          linkText={page.cta_link_text}
        />
        <Footer links={footerLinks} />
      </Layout>
    )
  }
}

TraceabilityPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageTraceabilityQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/traceability.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicTraceability {
      data {
        meta_title
        meta_description
        cta_title
        cta_text {
          html
          text
        }
        lead_text {
          html
          text
        }
        lead_image {
          url
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta_image {
          url
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2880, maxHeight: 2080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta_link {
          uid
        }
        cta_link_text
      }
    }
    allPrismicProducts(sort: { fields: data___order }) {
      nodes {
        uid
        data {
          product_name
          order
          catch {
            nz_quota_management_area
            packed_date
            vessel_landed_date(formatString: "DD MMMM YYYY")
          }
          product_image {
            url
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 520, maxHeight: 674) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TraceabilityPage

import React from 'react'
import Img from 'gatsby-image'
import { CSSTransition } from 'react-transition-group'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import IconPlus from './../SVG/Icons/IconPlus'

class TraceabilityAccordion extends React.Component {
  render() {
    const {
      activeId,
      activeProduct,
      toggleProductAccordion,
      toggleProductDate,
      activeProductDate,
      data,
      id,
    } = this.props

    let buttonClasses = ''
    let borderClasses = ''
    if (activeId === '') {
      buttonClasses += 'hover:opacity-50 '
    } else if (activeId !== id) {
      buttonClasses += 'opacity-20 hover:opacity-50'
    }

    if (activeId === '') {
      borderClasses += 'border-white-opaque-50'
    } else if (activeId !== id && activeId !== id + 1) {
      borderClasses += 'border-white-opaque-20'
    } else {
      borderClasses += 'border-white-opaque-50'
    }

    function formatDate(dateString) {
      var date = new Date(dateString)
      var months = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      var d = date.getDate()
      var m = months[date.getMonth()]
      var y = date.getFullYear()
      return (d <= 9 ? '0' + d : d) + ' ' + m + ' ' + y
    }

    return (
      <li
        className={`border-b transition-border-color transition-200 transition-custom ${borderClasses}`}
      >
        <button
          onClick={() => {
            toggleProductAccordion(data.uid, id)
          }}
          className={`handle-custom-cursor-click flex justify-between items-center md:items-start py-5 md:my-5 w-full focus:outline-none ${buttonClasses} transition-opacity transition-200 transition-custom`}
        >
          <span className="w-5/6 text-xl md:text-2xl font-light text-left leading-snug">
            {data.data.product_name}
          </span>
          <span className="w-6 h-6 md:w-8 md:h-8">
            <IconPlus
              className={`${
                activeProduct === data.uid ? 'rotate-45' : ''
              } transition-transform transition-200 transition-custom`}
            />
          </span>
        </button>
        <CSSTransition
          in={activeProduct === data.uid}
          timeout={{
            appear: 1200,
            enter: 1200,
            exit: 500,
          }}
          classNames="accordion-reveal"
          unmountOnExit
        >
          <div>
            <div className="md:flex md:justify-between pb-7 pt-4 border-t border-white-opaque-25">
              {data.data.product_image.url !== null && (
                <div className="md:w-1/3 max-w-xxs mb-10 md:mb-0">
                  <div className="w-full h-full shadow-lg">
                    <Img
                      fluid={
                        data.data.product_image.localFile.childImageSharp.fluid
                      }
                      alt={data.data.product_image.alt}
                    />
                  </div>
                </div>
              )}
              <div className="lg:w-1/3"></div>
              <div className="flex-shrink-0">
                <div className="flex items-start justify-between pt-4">
                  <div className="md:text-xl lg:text-2xl font-light leading-snug">
                    Packed on Date:
                  </div>
                  <div className="w-56 ml-8 sm:ml-10 sm:min-w-56 sm:w-auto">
                    <div className="select">
                      <select
                        className="select__inner"
                        value={activeProductDate}
                        onChange={e => {
                          toggleProductDate(e.target.value)
                        }}
                      >
                        <option value="" disabled>
                          Select Date
                        </option>
                        {data.data.catch.map((item, index) => {
                          return (
                            <option key={index} value={item.packed_date}>
                              {formatDate(item.packed_date)}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <AniLink
                      fade
                      className={`button leading-none mt-6 w-full${
                        activeProductDate === ''
                          ? ' pointer-events-none opacity-25'
                          : ''
                      }`}
                      to={`/traceability/products/${data.uid}/${activeProductDate}`}
                    >
                      Submit
                    </AniLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </li>
    )
  }
}

export default TraceabilityAccordion

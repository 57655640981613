import React from 'react'

const IconPlus = ({ className }) => {
  return (
    <svg
      className={`w-full h-full fill-current ${className && className} `}
      viewBox="0 0 36 36"
    >
      <path d="M18 35.5C8.4 35.5.5 27.6.5 18S8.4.5 18 .5 35.5 8.4 35.5 18 27.6 35.5 18 35.5zm0-34C8.9 1.5 1.5 8.9 1.5 18S8.9 34.5 18 34.5 34.5 27.1 34.5 18 27.1 1.5 18 1.5zm.5 9h-1v16h1v-16zM26 18H10v1h16v-1z"></path>
    </svg>
  )
}

export default IconPlus
